@import "constants";
@import "bootstrap-variables";
// Here you can add other styles
.burger-button {
  width: $sidebar-minimized-width;
  color: $tucum-primary-dark !important;
}

.rwt__tabs:not([data-rwt-vertical="true"]) {
  display: flex;
  flex-direction: column;
}

.rwt__tabs[data-rwt-vertical="true"] {
  display: flex;
}

.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid #ddd;
}

.rwt__tablist[aria-orientation="vertical"] {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
}

.rwt__tab {
  background: white;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 1rem 2rem;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  outline: 0;
  background-color: #f4f4f4;
  background-color: rgba(0, 0, 0, 0.05);
}

.rwt__tab[aria-selected="true"] {
  position: relative;
}

.rwt__tab[aria-selected="true"]:after {
  content: "";
  position: absolute;
}

.rwt__tablist:not([aria-orientation="vertical"])
.rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid #00d8ff;
}

.rwt__tablist[aria-orientation="vertical"]
.rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-left: 3px solid #00d8ff;
  border-right: 3px solid #00d8ff;
}

.rwt__tabpanel {
  background: white;
  width: 100%;
  padding: 1rem;
}

//CUSTOM CLASSES
$navbar-brand-logo: url('../img/logo.png');
$navbar-brand-logo-size: 55px auto;
$footer-height: 3rem;
//SCROLLBAR
/* width */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: $nextome-gray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background: $tucum-primary-dark;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $tucum-primary-dark;
}

.container-fluid {
  max-height: 100%;
}

.container {
  background-color: white;
  padding: 1rem;
  max-height: 100%;
  margin-top: 1.5rem;
}

.breadcrumb {
  margin-bottom: 1rem !important;
}

.modal-content {
  background-color: transparent;
  border: none;
  padding: 2rem;
}

input {
  background-color: transparent;
  border: 1px solid $tucum-secondary;
  color: $tucum-secondary;
  padding: 0.5rem;

  &::placeholder { /* Firefox, Chrome, Opera */
    color: $tucum-primary;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $tucum-primary;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $tucum-primary;
  }
}

::-webkit-file-upload-button {
  background: $tucum-primary-dark;
  color: $tucum-primary;
}

button:focus {
  outline: none;
}

.app-footer a {
  color: $tucum-primary-dark;
}

.form-group {
  margin-bottom: 0.2rem;
}

@media (min-width: 650px) {
  .modal-dialog {
    max-width: 650px;
  }

}

@import "bootstrap-variables";
