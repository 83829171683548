@import "../../scss/bootstrap-variables";

.navItem {
  cursor: pointer;
  margin-left: 2rem !important;
  color: $tucum-primary;

  &:hover {
    color: $tucum-primary-dark;
  }
}


.text {
  font-weight: bold;
  padding-left: 1rem;
  text-decoration: none;

  &:hover {
    color: $tucum-primary-dark;
  }
}

img.icon {
  height: 1.25rem;
  width: 20px;
  margin-right: 0.5rem;
}

.topmenu {
  padding-right: 0.75rem;
}

.logo {
  width: 100px;
  height: 50px;
  overflow: hidden;
  margin: 0;
}

.navbarBrand {
  display: block;
  height: 100%;
  background-color: $navbar-brand-bg;
  margin-left: 3rem;
}

@media screen and (max-width: 600px) {
  .logo {
    width: 200px
  }
  .navbarBrand {
    margin-top: -35%;
    margin-left: 0;
  }
}
