@import "../../scss/bootstrap-variables";

.ReactTable {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 1em
}

.rt-thead {
  background-color: map-get($theme-colors, secondary);
  color: white
}

.-headerGroups > .rt-tr > .rt-th[role="columnheader"] {
  background: map-get($theme-colors, primary);
  color: white;
  font-weight: bold;
}

.ReactTable .rt-td {
  text-align: center;
  margin: auto
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background-color: rgba(map-get($theme-colors, secondary), 0.1)
}

.ReactTable .-pagination {
  padding: 1em
}

.ReactTable .-pagination .-btn {
  background-color: map-get($theme-colors, primary);
  color: white;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background-color: map-get($theme-colors, primary-dark);
  color: white;
}

.ReactTable .-pagination .-btn:disabled {
  opacity: .5;
  cursor: default;
}