// Override Boostrap variables
@import "bootstrap-variables";
// Import Bootstrap source files from node_modules
@import "../../node_modules/bootstrap/scss/bootstrap";
// Import vendors styles
@import "vendors/vendors";
// Import core styles
@import "core/core";
// Custom styles
@import "custom";
