@import "../../scss/bootstrap-variables";

button {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25;
  transition: all 0.15s ease-in-out;
  border-radius: 5px;
  margin: 5px;
}

.block {
  display: block;
  width: 100%;
}

.circle {
  border-radius: 50%;
}

button.primary {
  background-color: map-get($theme-colors, primary);
  color: $white;
}

button.primary:hover {
  background-color: map-get($theme-colors, primary-dark);
  color: $white;
}

button.primary.outline {
  background-color: $white;
  color: map-get($theme-colors, primary);
  border-color: map-get($theme-colors, primary);
}

button.primary.outline:hover {
  background-color: map-get($theme-colors, primary);
  color: $white;
}

button.secondary {
  background-color: map-get($theme-colors, secondary);
  color: $white;
}

button.secondary:hover {
  background-color: map-get($theme-colors, primary-dark);
  color: $white;
}

button.secondary.outline {
  color: map-get($theme-colors, secondary);
  background-color: $white;
  border-color: map-get($theme-colors, secondary);
}

button.secondary.outline:hover {
  color: $white;
  background-color: map-get($theme-colors, primary);
}

button.red {
  background-color: red;
  color: $white;
}

button.red:hover {
  background-color: darkred;
  color: $white;
}

button.red.outline {
  color: red;
  background-color: $white;
  border-color: red;
}

button.red.outline:hover {
  color: $white;
  background-color: red;
}

