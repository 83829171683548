// Bootstrap overrides
@import "constants";

//
// Color system
//

$white: #fff;
$gray-100: #f0f3f5;
$gray-200: #c2cfd6;
$gray-300: #a4b7c1;
$gray-400: #869fac;
$gray-500: #678898;
$gray-600: #536c79;
$gray-700: #3e515b;
$gray-800: #29363d;
$gray-900: #151b1e;
$black: #000 !default;

$blue: #20a8d8;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #f86c6b;
$orange: #f8cb00;
$yellow: #ffc107 !default;
$green: #4dbd74;
$teal: #20c997 !default;
$cyan: #63c2de;

$colors: (
        blue: $blue,
        indigo: $indigo,
        purple: $purple,
        pink: $pink,
        red: $red,
        orange: $orange,
        yellow: $yellow,
        green: $green,
        teal: $teal,
        cyan: $cyan,
        white: $white,
        gray: $gray-600,
        gray-dark: $gray-800,
);

$theme-colors: (
        primary: $tucum-primary,
        primary-dark: $tucum-primary-dark,
        secondary: $tucum-secondary,
        success: $green,
        info: $cyan,
        warning: $yellow,
        danger: $red,
        light: $gray-100,
        dark: $gray-800
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: false;

// Body
//
// Settings for the `<body>` element.

$body-bg: $tucum-background;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.875rem;

//Header
$navbar-brand-bg:$navbar-bg;
$navbar-color:white;
$navbar-padding-y:0.5rem;
$navbar-padding-x:1rem;

// Breadcrumbs
$breadcrumb-bg: $navbar-bg;

// Cards

$card-border-color: $gray-200;
$card-cap-bg: $gray-100;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;

// Buttons

$btn-secondary-border: $gray-300;

// Progress bars

$progress-bg: $gray-100;

// Tables

$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;

// Forms

$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;
